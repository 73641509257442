import http from "./httpService";

const apiEndpoint = "/users";

export function register(user) {
  if (user.customerAbreviatura === "KO")
    return http.post(apiEndpoint, {
      email: user.username,
      password: user.password,
      name: user.name,
      cod_atleta: user.cod_atleta,
      customerAbreviatura: user.customerAbreviatura,
      telemovel: user.telemovel,
      isOnlyQRCode: true,
    });

  return http.post(apiEndpoint, {
    email: user.username,
    password: user.password,
    name: user.name,
    cod_atleta: user.cod_atleta,
    customerAbreviatura: user.customerAbreviatura,
    telemovel: user.telemovel,
  });
}
