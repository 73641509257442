import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import logger from "./services/logService";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "font-awesome/css/font-awesome.css";
import "./index.css";
//import "./ko.css";

/* 2020-01-13
Para resolver o problema responsivo do botão COLLAPSE e DROPDOWN
1. Acrescentar a importação 'import "bootstrap/dist/js/bootstrap.bundle.min";'
2. Instalar JQUERY... >npm i jquery
*/

logger.init();

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
