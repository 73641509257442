/* 
 2021-01-15
*/

import httpCustomer from "./httpServiceCustomer";

const apiEndpoint = "/ativos";

export function getValidade(cod_atleta) {
    return httpCustomer.get(apiEndpoint + "/" + cod_atleta);
}
