/* 
 2021-01-15
*/

import React, { Component } from 'react';
import auth from "../services/authService";
import { toast } from "react-toastify";
import { getValidade } from '../services/validadeService';
import { postConsumoVideos } from '../services/videoService';

const user = auth.getCurrentUser();

class Videos extends Component {
    
    /*state = { permissao: false }

    async componentDidMount() {
            
        const { data: ativos } = await getValidade(user.cod_atleta);
        
        if (ativos.length > 0)
            this.setState({ permissao : true });
        else
            this.setState({ permissao : false });
    
    }
    */

    handleClick = async (actividade) => {

        const { data: ativos } = await getValidade(user.cod_atleta);

        if (ativos.length === 0) return toast.info("Para ter acesso a Aulas Online, queira contactar-nos.");
                
        if (actividade.videoName) {
            this.props.history.push('/video/' + actividade.videoName);
            await postConsumoVideos(user.cod_atleta, actividade._id);  //2021-01-19
        }
        else if (actividade.imagem) {
            //this.props.history.push('/image/' + exercicio.imagem);
            this.props.history.push('/image/' + actividade._id);
        }
        else return;

    }

    render() { 

        //const urlBase = "https://appdfsiresources.s3-eu-west-1.amazonaws.com/lifeclub"
        //@TO-REVIEW
        const urlBase = "http://lifeclub.pt/aulasonline"
        
        return ( <div>
                    {/*
                        // para cada treino
                        this.state.permissao ? this.props.videos.map((item, i) => 
                            <div key={i}>
                                <div className="picture"><img src={urlBase + "/" + item.imagem} width="100%" onClick={() => this.handleClick(item)}></img></div>
                                <div style={{padding: "5px"}}></div>
                            </div>
                        )
                        : <p>Para ter acesso a aulas online, queira contactar-nos, de segunda a sexta-feira das 9:00-12:00 e 14:00 às 18:00, através do telemóvel <strong>934491374</strong> e email <strong>info@lifeclub.pt</strong>.</p>
                    */}
                    {
                        this.props.videos.map((item, i) => 
                        <div key={i}>
                            <div className="picture"><img src={urlBase + "/" + item.imagem} width="100%" onClick={() => this.handleClick(item)}></img></div>
                            <div style={{padding: "5px"}}></div>
                        </div>
                        )
                    }
                </div>
        );
    }
}
 
export default Videos;