/*
* Contacta servidor HEROKU e obtém o token com os atributos do utilizador.
* Faz o reload da aplicação contactando o servidor do cliente, identificado no token.
* 
*/

import React from "react";
import { Link } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import auth from "../services/authService";

import { toast } from "react-toastify";
import "../login.css";

class LoginForm extends Form {
  state = {
    data: { username: "", password: "" },
    errors: {},
  };

  schema = {
    /*username: Joi.string().required().label("Username"),*/
    username: Joi.string().required().label("Número de sócio"),
    password: Joi.string().required().label("Senha"),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;

      await auth.login(data.username, data.password); // armazena em 'localStorage' o token.

      toast("Login com sucesso.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      window.location = "/"; // 1. reloads the APP, this time, with the token (user) present.
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
  
    // 2. se foi feito login com sucesso, exibe os horários de hoje.

    //if (auth.getCurrentUser()) return <Redirect to="/horarios/0" />;

    // Esta segunda opção impede de recuar no histórico para o ecran de LOGIN.

    //2023-09-11
    //if (auth.getCurrentUser()) {
    //  this.props.history.replace("/horarios/0");
    //  return null;
    //}
    const user = auth.getCurrentUser();
    if (user) {
      user.isOnlyQRCode ? this.props.history.replace("/qrcode") : this.props.history.replace("/horarios/0");
      return null;
    } 

    return (
      <div className="login-body">
        <form className="form-signin" onSubmit={this.handleSubmit}>
          {/*{this.renderInput("username", "", true, "E-mail")}*/}
          {this.renderInput("username", "", true, "Número de sócio")}
          {this.renderInput("password", "", false, "Senha", "password")}
          {this.renderButton("Entrar")}
          <div>
            <Link
              style={{ fontSize: 15, textAlign: "right" }}
              className="navbar-brand"
              to="/registar"
            >
              Inscrever-se
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default LoginForm;
