import React, { Component } from "react";
import SearchBox from "./searchBox";
import HorariosTable from "./horariosTable";

class Horarios extends Component {

  /* 2020-10-15
  state = { isPresente: [] };

  async componentDidMount() {
    const user = auth.getCurrentUser();
    if (user) {
      const { data: isPresente } = await getCheckin(user.cod_atleta);
      this.setState({ isPresente });
    }
  }
  2020-10-15 */

  render() {
    const {
      horarios,
      isPresente, // 2020-10-15
      sortColumn,
      onSort,
      searchQuery,
      onChangeQuery,
      onReserva,
      onConfirmaReserva,
    } = this.props;

    let filteredHorarios = horarios;

    if (this.props.match.params.id)
      filteredHorarios = horarios.filter(
        (h) => h.dia === parseInt(this.props.match.params.id)
      );

    const totalHorarios = filteredHorarios.length
      ? `${filteredHorarios.length} horario(s)`
      : "";

    const periodo = this.props.match.params.id
      ? this.props.match.params.id === "0"
        ? "Hoje"
        : "Amanhã"
      : "";

    return (
      <div>
        {/*<h3>{totalHorarios}</h3>*/}
        <span>
          <strong>{this.props.match.params.id ? periodo : ""}</strong>
        </span>
        {horarios.length > 0 && <div>          
          <SearchBox value={searchQuery} onChange={onChangeQuery} />
          <HorariosTable
            horarios={filteredHorarios}
            isPresente={isPresente} // isPresente={this.state.isPresente} 2020-10-15
            sortColumn={sortColumn}
            onSort={onSort}
            onReserva={onReserva}
            onConfirmaReserva={onConfirmaReserva}
            //espacos_todos={props.match.params.id ? false : true}
            espacos_todos={false}
          />
        </div>}        
      </div>
    );
  }
}

export default Horarios;
