/* 
 2021-01-15
*/

import React, { Component } from 'react';
import auth from "../services/authService";
import { toast } from "react-toastify";
import { getValidade } from '../services/validadeService';

const user = auth.getCurrentUser(); // 1. Is there a token in the storage?

class Covid extends Component {

    /*
    state = {  permissao: false  }

    async componentDidMount() {
        const { data: ativos } = await getValidade(user.cod_atleta);
         
        if (ativos.length > 0)
            this.setState({ permissao : true });
        else
            this.setState({ permissao : false });
    }
    */

    //2021-01-16
    handlePermissao = async () => {

        const { data: ativos } = await getValidade(user.cod_atleta);
         
        if (ativos.length > 0)
            //@TO-REVIEW
            window.location.href = "https://forms.gle/6kZqxvE4Abd7qMLN6";
        else
            toast.info("Para ter acesso a Serviços Online, queira contactar-nos.");
    }
    
    render() { 

        //const formEnabled = true;

        return ( <>
            {/*<p>Caro cliente, informamos que as reservas estão suspensas durante o período de confinamento.</p>*/}


            {user.customerAbreviatura === "LIFECLUB" && <p style={{textAlign:"left", padding:"10px"}}>Informamos que por imposição do Decreto-Lei aprovado na reunião de Conselho de Ministros de 13 de janeiro de 2021, encerraremos temporariamente o nosso espaço a partir de 15 de janeiro de 2021 e por período indeterminado, até autorização de reabertura.</p>}
            
            {/*user.customerAbreviatura === "LIFECLUB" && <button className="btn btn-primary" onClick={this.handlePermissao}>Clique aqui para informações sobre Serviços Online...</button>*/}

            {/*2021-02-01*/}
            {/*{user.customerAbreviatura === "LIFECLUB" && <p><a  href="https://forms.gle/6kZqxvE4Abd7qMLN6" target="_blank">Clique aqui para informações sobre Serviços Online...</a></p>}*/}

            {user.customerAbreviatura === "LIFECLUB" &&         
                <div style={{padding:"10px"}}>
                    <div className="servicosOnlineCentro">
                    <img
                        src="http://lifeclub.pt/servicosonline/lifeclub_nutricao_servicosonline.jpg"
                        width="48%"
                        height="100%"
                        />
                    </div>
                    <div
                        style={{
                        backgroundColor: "#fc6907",
                        marginTop: "2px",
                        marginBottom: "15px",
                        padding: "2px",
                        }}
                    >
                        <a className="servicosOnlineLink" href="http://lifeclub.pt/infomail/2021_nutricao_lifeclub/infomail_lifeclub_2021_nutricao.html" target="_blank">Clique para Info Mail Aconselhamento Nutricional</a>
                    </div>
                    <div
                        className="servicosOnline"
                    >
            			<img
                        src="http://lifeclub.pt/servicosonline/lifeclub_planosdetreino_servicosonline.jpg"
                        width="48%"
                        height="50%"
                        />
                        <img
                        src="http://lifeclub.pt/servicosonline/lifeclub_servicos_pt_servicosonline.jpg"
                        width="48%"
                        height="50%"
                        />
                    </div>
                    <div
                        style={{
                        backgroundColor: "#fc6907",
                        marginTop: "2px",
                        padding: "2px",
                        }}
                    >
                        <a className="servicosOnlineLink" href="https://forms.gle/6kZqxvE4Abd7qMLN6" target="_blank">Clique para informações sobre Serviços Online</a>
                    </div>
                </div>
            }

            {user.customerAbreviatura === "LIFECLUB" && <p style={{textAlign:"left", padding:"10px"}}>Disponibilizaremos serviços para todos os Utilizadores desta nossa
plataforma digital. Estaremos sempre contactáveis de segunda a sexta-feira
das 09 às 12 horas e das 14 às 18 horas, através do telemóvel <strong>934491374</strong>, bem
como através deste endereço de e-mail: <strong>info@lifeclub.pt</strong>. Nas suas
comunicações connosco, por favor, indique sempre o seu número de Utilizador
e evite a duplicação de mensagens nos nossos canais.</p>}

            
            {user.customerAbreviatura === "LIFECLUB" && <p style={{textAlign:"left", padding:"10px"}}>Continuamos juntos, ao seu lado e a acompanhar a evolução da situação, desejando que as atuais medidas tenham resultados, para que possamos voltar a ver-nos novamente em segurança.</p>}
            {user.customerAbreviatura === "LIFECLUB" && <p>Life Club, o seu ginásio em Gaia!</p>}
            
            {user.customerAbreviatura === "LIBERTY" && <p>Informamos que por imposição do decreto de lei aprovado na reunião do conselho de Ministros de 13 de janeiro, encerramos temporariamente o espaço até autorização de reabertura.</p>}
            
            {/*{user.customerAbreviatura === "LIFECLUB"  && (<a className="btn btn-primary" href="https://forms.gle/3MVNyYmMsamoSxyS8">Formulário</a>)}*/}
            
            {/*<p><a className="btn btn-primary" href="https://www.w3.org/" target="_blank">W3C</a></p>*/}
            {/*<p><a className="btn btn-primary" href={this.handlePermissao ? "https://www.w3.org/" : ""} target="_blank">W3C</a></p>*/}

            
            {
                /*
                user.customerAbreviatura === "LIFECLUB" && 
                    <form action="https://forms.gle/6kZqxvE4Abd7qMLN6"> 
                        {this.state.permissao
                            ? 
                                <input className="btn btn-primary" type="submit" value="Serviços Online" />
                            : 
                                <div>
                                    <input disabled className="btn btn-primary" type="submit" value="Serviços Online" />
                                    <p><i>Para ter acesso a Serviços Online, queira contactar-nos.</i></p>
                                </div>}
                    </form>                
                */
            }
            
        </> );
    }
}
  
export default Covid;