import axios from "axios";
import auth from "./authService";
import logger from "./logService";
import { toast } from "react-toastify";

let user = auth.getCurrentUser();
if (!user) user = { customerAbreviatura: "" };

const instanceAxios2 = axios.create({});

instanceAxios2.defaults.baseURL =
  process.env["REACT_APP_CUSTOMER_" + user.customerAbreviatura];
//exemplos:
//instanceAxios2.defaults.baseURL = process.env.REACT_APP_CUSTOMER_DINOS;
//instanceAxios2.defaults.baseURL = process.env.REACT_APP_CUSTOMER_KO;
//instanceAxios2.defaults.baseURL = process.env.REACT_APP_CUSTOMER_FITNESS;

//2023-09-07
if (user)
  instanceAxios2.defaults.headers.common["x-auth-token"] = auth.getJwt();

instanceAxios2.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  //2021-11-15
  if (expectedError) {
    if (error.response.status === 429) {
      toast.info("Aguarde...");
    }
  }

  if (!expectedError) {
    logger.log(error);

    //2020-07-30
    //toast.error("Ocorreu um erro inesperado."); // toast.error("An unexpected error occurrred.");
    toast.error("Falha de comunicação.");
  }

  return Promise.reject(error);
});

/*2023-09-07
function setJwt(jwt) {
  instanceAxios2.defaults.headers.common["x-auth-token"] = jwt;
}
*/

export default {
  get: instanceAxios2.get,
  post: instanceAxios2.post,
  patch: instanceAxios2.patch,
  put: instanceAxios2.put,
  //setJwt, //2023-09-07
};
