import React from "react";
import { Link } from "react-router-dom";

const ListGroupLinks = ({
  items,
  textProperty,
  valueProperty,
  selectedItem,
  onItemSelect,
}) => {
  return (
    <div className="list-group">
      {items.map((item) => (
        <Link
          key={item[valueProperty]}
          onClick={() => onItemSelect(item)}
          to={`/horarios/${item[valueProperty]}`}
          className={
            item === selectedItem
              ? "list-group-item list-group-item-action active"
              : "list-group-item list-group-item-action"
          }
        >
          {item[textProperty]}
        </Link>
      ))}
    </div>
  );
};

ListGroupLinks.defaultProps = {
  textProperty: "name",
  valueProperty: "_id",
};

export default ListGroupLinks;
