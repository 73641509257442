/* 
 2022-09-01
*/
import React from 'react';

function News(props) {
    
    const urlBase = "http://lifeclub.pt/novidades"

    return (
        <div>
            {
                props.news.map((item, i) => 
                <div key={i}>
                    <div className="picture"><img src={urlBase + "/" + item.imagem} width="100%" alt=""></img></div>
                    <div style={{padding: "5px"}}></div>
                </div>
                )
            }
        </div>
    );
}

export default News;