import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import * as userService from "../services/userService";
import auth from "../services/authService";
import { getEmailVerify } from "../services/peopleService";
import { getEmailVerify1 } from "../services/peopleService1"; //2023-03-20
import "../register.css";

class RegisterForm extends Form {
  state = {
    data: {
      username: "",
      telemovel: "",
      password: "",
      customerAbreviatura: "",
      cod_atleta: 0,
      name: "--",
    },
    errors: {},
  };

  schema = {
    /*username: Joi.string().required().email().label("Username"),*/
    username: Joi.string().required().label("Número de sócio") /*2020-06-11*/,
    telemovel: Joi.number().required().label("Telemóvel") /*2020-06-11*/,
    password: Joi.string().required().min(5).label("Senha"),
    customerAbreviatura: Joi.string().required().label("Nome da instituição"),
    cod_atleta: Joi.number().integer(),
    name: Joi.string(),
  };


  doSubmit = async () => {

    try {
      /* if (
        this.state.data.customerAbreviatura.toUpperCase() !== "LIBERTY" &&
        this.state.data.customerAbreviatura.toUpperCase() !== "KO" &&
        this.state.data.customerAbreviatura.toUpperCase() !== "FITNESS"
      ) {
        const errors = { ...this.state.errors };
        errors.username = "Instituição inválida.";
        this.setState({ errors });
        return;
      }*/

      if (
        typeof process.env[
          "REACT_APP_CUSTOMER_" +
            this.state.data.customerAbreviatura.toUpperCase()
        ] === "undefined"
      ) {
        // Se não está definida a variável...
        const errors = { ...this.state.errors };
        errors.username = "Nome da instituição inválido.";
        this.setState({ errors });
        return;
      }

      // verifica se existe um cliente com o e-mail fornecido; se sim, recebe cod_atleta

      if (this.state.data.customerAbreviatura.toUpperCase() === "DEMOGYM") { //2023-03-20
        const { data: user } = await getEmailVerify1({

          codigo: this.state.data.username,
          telemovel: this.state.data.telemovel,  
          customerAbreviatura: this.state.data.customerAbreviatura.toUpperCase(),
        });
        if (user.length === 0) {
          // não existe cliente ou funcionário com o e-mail fornecido.
          const errors = { ...this.state.errors };
          errors.username = "Número de sócio ou telemóvel inválidos.";
          this.setState({ errors });
          return;
        } else {
          let user1 = { ...this.state.data };
          user1.cod_atleta = user[0].cod_atleta;
  
          //2020-05-30
          user1.customerAbreviatura = this.state.data.customerAbreviatura.toUpperCase();
  
          this.setState({ data: user1 });
        }        
      } else {
        const { data: user } = await getEmailVerify({
          /*
          email: this.state.data.username,
          */
          codigo: this.state.data.username,
          telemovel: this.state.data.telemovel,
  
          customerAbreviatura: this.state.data.customerAbreviatura.toUpperCase(),
        });  
        if (user.length === 0) {
          // não existe cliente ou funcionário com o e-mail fornecido.
          const errors = { ...this.state.errors };
          errors.username = "Número de sócio ou telemóvel inválidos.";
          this.setState({ errors });
          return;
        } else {
          let user1 = { ...this.state.data };
          user1.cod_atleta = user[0].cod_atleta;
  
          //2020-05-30
          user1.customerAbreviatura = this.state.data.customerAbreviatura.toUpperCase();
  
          this.setState({ data: user1 });
        }        
      }

      const response = await userService.register(this.state.data);
      auth.loginWithJwt(response.headers["x-auth-token"]);
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
      //2020-06-12

      if (ex.response && ex.response.status === 404) {
        const errors = { ...this.state.errors };
        errors.username = "Instituição inválida.";
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="register-body">
        <form className="form-register" onSubmit={this.handleSubmit}>
          {/*{this.renderInput("username", "", true, "E-mail")}*/}
          {this.renderInput("username", "", true, "Número de sócio")}
          {this.renderInput("telemovel", "", false, "Telemóvel")}
          {this.renderInput("password", "", false, "Senha", "password")}
          {this.renderInput(
            "customerAbreviatura",
            "",
            false,
            "Nome da instituição"
          )}
          {this.renderButton("Continuar")}
        </form>
      </div>
    );
  }
}

export default RegisterForm;
