/* 
 2021-01-15
*
* HTMLMediaElement controlsList Sample
* https://googlechrome.github.io/samples/media/controlslist.html
*
* How can I capture the right-click event in JavaScript? [duplicate]
* https://stackoverflow.com/questions/4235426/how-can-i-capture-the-right-click-event-in-javascript/4236294
*/

import React from "react";

/*
  Para impedir o comportamento normal associado ao botão direito
  que permitia fazer o download do vídeo.
*/

const handleonContextMenu = (e) => {
  e.preventDefault();
  return false;
}

const VideoPlayer = (props) => {

  //urlVideo = "https://appdfsiresources.s3-eu-west-1.amazonaws.com/lifeclub/movie.mp4"
  //urlVideo = "https://appdfsiresources.s3-eu-west-1.amazonaws.com/lifeclub/flower.webm"

  //2021-01-15
  //const urlBase = "https://appdfsiresources.s3-eu-west-1.amazonaws.com/lifeclub"
  //const urlBase = "http://lifeclub.pt/aulasonline/life_pilates"
  //const urlBase = "http://dfsi.pt/aulas"
  
  //@TO-REVIEW
  const urlBase = "http://lifeclub.pt/aulasonline"
  
  //2021-01-16
  const urlVideo = urlBase + "/" + props.match.params.id + "?t=" + new Date();
  //const urlVideo = 'https://vimeo.com/user131383627/review/501220566/e3f0f3b3e5'
  //const urlVideo = 'https://vimeo.com/501220566'
  //https://vimeo.com/501220566

  return (
        //<video src={urlVideo} width="100%" autoPlay style={{pointerEvents:"none"}}>
        //<video src={urlVideo} width="100%" height="100%" autoPlay controls muted>
        <video 
          onContextMenu={(e) => handleonContextMenu(e)}
          width="100%" 
          height="100%" 
          autoPlay 
          controls controlsList="nodownload"
          /*style={{pointerEvents:"none"}}*/>
            <source src={urlVideo} type="video/mp4"></source>
          {/*<source src={urlVideo} type="video/mp4" />
          <source src={urlVideo}/>*/}
        </video>
      );
  };
  
  export default VideoPlayer;
  