import React from "react";
import { Link, NavLink } from "react-router-dom";
import imgQRCode from '../assets/qrcode.png'; //2023-02-21 
import "../App.css";

//2021-01-19 const NavBarNew = ({ user, onItemSelect }) => {
const NavBarNew = ({ user, exibePaginaInfo, visitouPaginaInfo, onItemSelect }) => {
  let classNameText = "";
  if (!user) {
    classNameText = "navbar navbar-expand-sm navbar-light";
  } else {
    classNameText =
      "navbar navbar-expand-sm navbar-light nav-bgcolor" +
      "-" +
      user.customerAbreviatura;
  }

  return (
    <nav className={classNameText}>
      {!user && (
        <Link className="navbar-brand" to="/">
          <img
            src={process.env.PUBLIC_URL + "/logo_DFSI" + ".png"}
            alt=""
          ></img>
        </Link>
      )}
      {user && (
        <Link
          className="navbar-brand"
          
          //2023-10-18
          //onClick={() => onItemSelect({ _id: 0 })}
          onClick={() => onItemSelect(user.isOnlyQRCode ? { _id: -1 } : { _id: 0 })}
          
          /*2021-01-19 to="/horarios/0"*/
          //2021-04-04

          //2023-09-11
          //to="/horarios/0"
          to={user.isOnlyQRCode  ? "/qrcode" : "/horarios/0"}

          //2021-04-04 to={visitouPaginaInfo === true ? "/horarios/0" : "/main"}
        >
          {/* 2020-06-12 */}
          {/*{user.customerAbreviatura}*/}
          <img
            src={
              process.env.PUBLIC_URL +
              "/logo_" +
              user.customerAbreviatura +
              ".png"
            }
            width="87"
            height="50"
            alt=""
          ></img>
        </Link>
      )}

      {user && (
        (user.isOnlyQRCode && (<Link
          key={2}
          style={{ fontSize: "100%" }}
          className="navbar-brand"
          to="/qrcode"
        >
          <img src={imgQRCode} width="25px"></img>
        </Link>))
        ||
        (exibePaginaInfo === false && (<React.Fragment>
          <Link
            key={0}
            style={{ fontSize: "100%" }}
            className="navbar-brand"
            onClick={() => onItemSelect({ _id: 0 })}
            to="/horarios/0"
          >
            Hoje
          </Link>
          <Link
            key={1}
            style={{ fontSize: "100%" }}
            className="navbar-brand"
            onClick={() => onItemSelect({ _id: 1 })}
            to="/horarios/1"
          >
            Amanhã
          </Link>
          {/* 2021-01-15 */}
          {
            
            user.customerAbreviatura === "LIFECLUB" && <Link
              key={2}
              style={{ fontSize: "100%" }}
              className="navbar-brand"
              to="/videos"
            
            /* 2023-05-17 
              user.customerAbreviatura === "LIFECLUB" && <Link
              key={2}
              style={{ fontSize: "100%" }}
              className="navbar-brand"
              to="/news"
              */
            >
              {/*Novidades 2022-09-01 Aulas Online */}
              Aulas Online {/*2023-05-17 Novidades */}
            </Link>
          }
          {/* 2023-02-21 */}
          {
            user.customerAbreviatura === "LIBERTY" && <Link
              key={2}
              style={{ fontSize: "100%" }}
              className="navbar-brand"
              to="/qrcode"
            >
              <img src={imgQRCode} width="25px"></img>
            </Link>
          }
        </React.Fragment>)) 
        || 
        (exibePaginaInfo === true && visitouPaginaInfo === true && (<React.Fragment>
          <Link
            key={0}
            style={{ fontSize: "100%" }}
            className="navbar-brand"
            onClick={() => onItemSelect({ _id: 0 })}
            to="/horarios/0"
          >
            Nutrição {/*2021-02-01 Hoje*/}
          </Link>
          <Link
            key={1}
            style={{ fontSize: "100%" }}
            className="navbar-brand"
            onClick={() => onItemSelect({ _id: 1 })}
            to="/horarios/1"
          >
            PT {/*2021-02-01 Amanhã */}
          </Link>
          {/* 2021-01-15 */}
          {
            /*
            user.customerAbreviatura === "LIFECLUB" && <Link
              key={2}
              style={{ fontSize: "100%" }}
              className="navbar-brand"
              to="/videos"
            */
            user.customerAbreviatura === "LIFECLUB" && <Link
              key={2}
              style={{ fontSize: "100%" }}
              className="navbar-brand"
              to="/news"
            >
              Novidades {/* Aulas Online */}
            </Link>
          }
        </React.Fragment>))
      )}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
        {user && (
          <NavLink
            style={{ color: "black" }}
            className=" nav-link"
            to="/logout"
          >
            Fechar
          </NavLink>
        )}
      </div>
    </nav>
  );
};

export default NavBarNew;
