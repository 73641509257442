import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import auth from "../services/authService";

const user = auth.getCurrentUser(); // 1. Is there a token in the storage?

const CodigoQR = () => {
    const [qrcode, setQrcode] = useState('');

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
      /* QRCode.toDataURL("1000", {width: 300}, (err, codigo) => {
        setQrcode(codigo);
      }) */

      if (user) {
        //2023-02-21
        //QRCode.toCanvas(document.getElementById('canvas'), user.email, {width: 200}, (error) => {
          QRCode.toCanvas(document.getElementById('canvas'), "QR" + String(user.email).padStart(8, '0'), {width: 200}, (error) => {
          if (error) return console.error(error)
          console.log('success! ', user.email);}) 
      }      
    }, []);
  
    return (
      <div style={{height:"80vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
        {qrcode && <img style={{width:"100%"}} src={qrcode} alt="" />}
        <canvas id="canvas"  ></canvas>
      </div>
    );
}
 
export default CodigoQR;