import React, { Component } from "react";
import { toast } from "react-toastify";

import DialogBox from "./common/dialogBox"; //2020-05-22
import auth from "../services/authService";
import Table from "./common/table";
import "../App.css";

const user = auth.getCurrentUser();

class HorariosTable extends Component {
  //2020-05-22
  state = {
    openDialogBoxOpenReservar: false,
    openDialogBoxCancelar: false,
    openDialogBoxConfirmar: false,
    aula: {},
  };

  //2020-05-22
  handleRequestClose = () => {
    this.setState({ openDialogBoxOpenReservar: false });
    this.setState({ openDialogBoxCancelar: false });
    //2020-06-11
    this.setState({ openDialogBoxConfirmar: false });
  };

  //2020-05-22
  handleRequestReservar = (h) => {
    this.setState({ openDialogBoxOpenReservar: false });
    this.props.onReserva(h);
  };
  //2020-05-22
  handleRequestCancelar = (h) => {
    this.setState({ openDialogBoxCancelar: false });
    this.props.onReserva(h);
  };

  //2020-06-11
  handleRequestConfirmar = (h) => {
    this.setState({ openDialogBoxConfirmar: false });
    this.props.onConfirmaReserva(h);
  };

  salas = (espaco) => {
    if (espaco) {
      let columns = [
        {
          path: "sala_text",
          label: "Sala",
          //content: movie => <Link to={`/movies/${movie._id}`}>{movie.title}</Link>
        },
        { path: "abreviatura", label: "Modalidade" },
        { path: "dia", label: "Dia" },
        { path: "hora", label: "Hora" },
      ];
      return columns;
    } else {
      let columns = [
        {
          //2020-06-12 key: "horario",
          path: "abreviatura",
          content: (h) => (
            <div className="div-container">
              {/*<h5 style={{ textAlign: "left" }}>{h.abreviatura}</h5>*/}
              {/*<div className="div-fixed" style={{ textAlign: "left" }}>
                <img
                  src="https://picsum.photos/200"
                  width="50"
                  height="50"
                  alt={h.abreviatura}
                />
              </div>*/}
              {/* 2020-10-07 Se horário aberto? */}
              {h.reservado === "0" ? (
                <div className="div-flex-item">
                  <span>{h.abreviatura}</span>
                  <p
                    style={{
                      fontSize: 10,
                      fontStyle: "italic",
                      textAlign: "left",
                      marginBottom: h.info_online ? 0 : 10, // 2022-04-08
                    }}
                  >
                    <span>
                      <strong>{h.espaco}</strong>
                    </span>
                    {
                      /* 2020-07-06 h.limite - (h.conf + h.reser) !== 0*/
                      h.limite - (h.conf + h.reser) > 0
                        ? /* 2020-06-12 */
                          /*? `Número de vagas: ${h.limite - (h.conf + h.reser)}`*/
                          `: ${h.limite - (h.conf + h.reser)} vagas de ${
                            h.limite
                          }`
                        : ""
                    }
                  </p>
                  {/*2022-04-08*/}
                  {h.info_online && <span style={{fontSize: 12,backgroundColor:"#007bff",borderRadius:"2px", padding:"2px 5px",color:"white"}}>{h.info_online}</span>}
                </div>
              ) : (
                <div className="div-flex-item">
                  <span>{h.abreviatura}</span>
                  <p
                    style={{
                      fontSize: 10,
                      fontStyle: "italic",
                      textAlign: "left",
                      color: "#007bff",
                    }}
                  >
                    {/*<span>
                      <strong>{h.espaco}</strong>
                    </span>
                    */}
                    <span>Reservar a partir de </span>
                    <span
                      style={{
                        fontSize: 11,
                        fontStyle: "italic",
                        textAlign: "left",
                        color: "#007bff",
                      }}
                    >
                      <strong>{h.reservado}</strong>
                    </span>
                  </p>
                </div>
              )}
            </div>
          ),
          label: "Modalidade",
        },
        { path: "hora", label: "Hora" },
      ];
      if (user) {
        columns.push(this.reservarColumn);
      }
      /*if (user && this.props.isPresente.length !== 0) {
        columns.push(this.confirmarColumn);
      }*/

      return columns;
    }
  };

  /* { path: "abreviatura", label: "Modalidade" }, */

  /*  columns = [
    { path: "abreviatura", label: "Modalidade" },
    { path: "hora", label: "Hora" },
  ]; */

  reservarColumn = {
    key: "reservar",
    content: (h) => {
      if (this.props.isPresente.length !== 0 && h.cod_presenca && h.dia === 0)
        return (
          <React.Fragment>
            <div>
              <button
                onClick={() => {
                  //2020-05-22
                  //this.props.onReserva(h);
                  !h.cod_presenca
                    ? //2020-07-06 h.reser + h.conf === h.limite
                      h.reser + h.conf >= h.limite
                      ? this.props.onReserva(h)
                      : this.setState({
                          openDialogBoxOpenReservar: true,
                          aula: h,
                        })
                    : this.setState({ openDialogBoxCancelar: true, aula: h });
                }}
                className={
                  !h.cod_presenca
                    ? //2020-07-06  h.reser + h.conf === h.limite
                      h.reser + h.conf >= h.limite
                      ? "btn btn-warning btn-sm disabled"
                      : h.reservado === "0" // 2020-10-07
                      ? "btn btn-outline-primary btn-sm"
                      : "btn btn-outline-secondary btn-sm"
                    : h.cod_presenca === 0
                    ? "btn btn-success btn-sm disabled"
                    : "btn btn-secondary btn-sm"
                }
              >
                {!h.cod_presenca
                  ? //2020-07-06  h.reser + h.conf === h.limite
                    h.reser + h.conf >= h.limite
                    ? "Esgotado"
                    : "Reservar"
                  : h.cod_presenca === 0
                  ? "Senha emitida"
                  : "Cancelar"}
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  //2020-05-22
                  //this.props.onConfirmaReserva(h);
                  this.setState({ openDialogBoxConfirmar: true, aula: h });
                }}
                className="btn btn-primary btn-sm m-2"
              >
                Confirmar
              </button>
            </div>
          </React.Fragment>
        );
      else
        return (
          <button
            onClick={() => {
              //2020-05-22
              //this.props.onReserva(h);
              !h.cod_presenca
                ? //2020-07-06 h.reser + h.conf === h.limite
                  h.reser + h.conf >= h.limite
                  ? this.props.onReserva(h)
                  : h.cod_presenca === 0
                  ? //2020-07-30
                    //? toast.warning("Senha já emitida!")
                    toast.info("Senha já emitida!")
                  : this.setState({ openDialogBoxOpenReservar: true, aula: h })
                : this.setState({ openDialogBoxCancelar: true, aula: h });
            }}
            className={
              !h.cod_presenca
                ? //2020-07-06 h.reser + h.conf === h.limite
                  h.reser + h.conf >= h.limite
                  ? "btn btn-warning btn-sm disabled"
                  : h.cod_presenca === 0
                  ? "btn btn-success btn-sm disabled"
                  : h.reservado === "0" // 2020-10-07
                  ? "btn btn-outline-primary btn-sm"
                  : "btn btn-outline-secondary btn-sm"
                : "btn btn-secondary btn-sm"
            }
          >
            {!h.cod_presenca
              ? //2020-07-06 h.reser + h.conf === h.limite
                h.reser + h.conf >= h.limite
                ? "Esgotado"
                : h.cod_presenca === 0
                ? "Senha emitida"
                : "Reservar"
              : "Cancelar"}
          </button>
        );
    },
  };

  /*confirmarColumn = {
    key: "confirmar",
    content: (h) => {
      if (this.props.isPresente.length !== 0 && h.cod_presenca && h.dia === 0)
        return (
          <button
            onClick={() => {
              //2020-05-22
              //this.props.onConfirmaReserva(h);
              this.setState({ openDialogBoxCancelar: true, aula: h });
            }}
            className="btn btn-primary btn-sm"
          >
            Confirmar
          </button>
        );
    },
  };*/

  /*
  constructor() {
    super();
    console.log("CONSTRUCTOR");
    console.log("COLUMNS:", this.columns);
    const user = auth.getCurrentUser();
    if (user) {
      this.columns.push(this.reservarColumn);
    }
  }
*/

  render() {
    const { horarios, onSort, sortColumn, espacos_todos } = this.props;

    const dia = this.state.aula.dia === 0 ? "" : " de amanhã";
    const msgReservar = `Confirma a reserva da aula de ${this.state.aula.abreviatura} às ${this.state.aula.hora}${dia}?`;
    const msgCancelar = `Confirma o cancelamento da reserva da aula de ${this.state.aula.abreviatura} às ${this.state.aula.hora}${dia}?`;
    //2020-06-11
    const msgConfirmar = `Confirma a participação na aula de ${this.state.aula.abreviatura} às ${this.state.aula.hora}${dia}?`;

    //<2021-11-05 Informação no ato da reserva (LIFECLUB) 
    const msg= `⚠️ ATENÇÃO ⚠️`
    //const msg1= `Informarmos que a partir do dia 15, poderá fazer a sua reserva para o dia seguinte desde as 21H30 do dia anterior.`
    //2021-11-30
    //2022-02-16
    //const msg1= `Informamos que no acesso a ginásios e academias é obrigatória a apresentação de certificado Covid da UE ou teste laboratorial com resultado negativo. Exceção, crianças com idade inferior a 12 anos.`
    //2024-07-27
    //const msg1= `Relembramos que o valor das mensalidades sofre alteração a partir de 01 de Março de 2022. Informação adicional, consultar na receção.`
    const msg1= `Informamos que no mês de Agosto o horário de funcionamento do clube ao sábado será das 09h00-14:00.`
    //>2021-11-05

    return (
      <div>
        <DialogBox
          isOpen={this.state.openDialogBoxOpenReservar}
          onRequestClose={this.handleRequestClose}
          onRequestContinuar={this.handleRequestReservar}
          item={this.state.aula}
        >
          {/* >2021-11-05 Informação no ato da reserva (LIFECLUB) */}
          {/* 2024-07-31 */} 
          {(user && user.customerAbreviatura === "LIFECLUB") ? <p style={{textAlign:"center", fontWeight:"bold"}}>{msg}</p> : ""}
          {(user && user.customerAbreviatura === "LIFECLUB") ? <p style={{textAlign:"center", fontWeight:"bold"}}>{msg1}</p> : ""}
          
          {/* <2021-11-05*/}
          {msgReservar}
        </DialogBox>
        <DialogBox
          isOpen={this.state.openDialogBoxCancelar}
          onRequestClose={this.handleRequestClose}
          onRequestContinuar={this.handleRequestCancelar}
          item={this.state.aula}
        >
          {msgCancelar}
        </DialogBox>
        <DialogBox
          isOpen={this.state.openDialogBoxConfirmar}
          onRequestClose={this.handleRequestClose}
          onRequestContinuar={this.handleRequestConfirmar}
          item={this.state.aula}
        >
          {msgConfirmar}
        </DialogBox>
        <Table
          columns={this.salas(espacos_todos)}
          //columns={this.columns}
          data={horarios}
          sortColumn={sortColumn}
          onSort={onSort}
        />
      </div>
    );
  }
}

export default HorariosTable;
