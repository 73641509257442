import httpCustomer from "./httpServiceCustomer";

const apiEndpoint = "/reservas";

export function postReserva(h) {
  return httpCustomer.post(apiEndpoint, {
    cod_tempo: h._id,
    cod_atleta: h.cod_atleta,
    cod_perfil: h.cod_perfil,
    periodo: h.dia,
  });
}

/*
export function anulaReserva(h) {
  return httpCustomer.put(apiEndpoint, {
    cod_presenca: h.cod_presenca,
    op: "Anular",
  });
}

//2020-06-11
export function confirmaReserva(h) {
  return httpCustomer.put(apiEndpoint, {
    cod_presenca: h.cod_presenca,
    op: "Confirmar",
  });
}
*/

//2020-09-29
export function anulaReserva(h) {
  return httpCustomer.post(apiEndpoint + "/alterar", {
    cod_presenca: h.cod_presenca,
    op: "Anular",
  });
}

//2020-09-29
export function confirmaReserva(h) {
  return httpCustomer.post(apiEndpoint + "/alterar", {
    cod_presenca: h.cod_presenca,
    op: "Confirmar",
  });
}
