/* 
 2021-01-19
*
*/

import React from "react";
import { postVisitouPaginaInfo } from '../services/infoService';      // 2021-01-19
import auth from "../services/authService";

const user = auth.getCurrentUser(); // 1. Is there a token in the storage?

const PaginaInfo = (props) => {

    //2021-01-19
    const urlBase = "http://lifeclub.pt/servicosonline"

    async function handleServicosOnline ()  {
        props.history.replace("/horarios/0");
        props.onVisitouPaginaInfo();
        await postVisitouPaginaInfo(user.cod_atleta,1);
    }

    if (props.exibePaginaInfo === null) {          // Por processar...
        return null;
    } else 
    if (props.exibePaginaInfo === false) {          // False
        props.history.replace("/horarios/0");
        return null;
    } else 
    if (props.exibePaginaInfo === true) {          // True
        if (props.visitouPaginaInfo === null) {    // Por processar...
            return null;
        } else
        if (props.visitouPaginaInfo === false) {    // Ainda não visitou...
            return (
                <div>
                    {/*<p><a  href="https://forms.gle/6kZqxvE4Abd7qMLN6" onClick={handleServicosOnline} target="_blank">Clique aqui para informações sobre Serviços Online...</a></p>*/}
                    <a  href="https://forms.gle/6kZqxvE4Abd7qMLN6" onClick={handleServicosOnline} target="_blank">
                        <img src={urlBase + "/" + "lifeclub_nutricao_servicosonline.jpg"} width="100%" height="100%" alt="Nutrição"/>
                    </a>
                    <div style={{padding: "5px"}}></div>
                    <a  href="https://forms.gle/6kZqxvE4Abd7qMLN6" onClick={handleServicosOnline} target="_blank">
                        <img src={urlBase + "/" + "lifeclub_planosdetreino_servicosonline.jpg"} width="100%" height="100%" alt="Plano de treino"/>
                    </a>
                    <div style={{padding: "5px"}}></div>
                    <a  href="https://forms.gle/6kZqxvE4Abd7qMLN6" onClick={handleServicosOnline} target="_blank">
                        <img src={urlBase + "/" + "lifeclub_servicos_pt_servicosonline.jpg"} width="100%" height="100%" alt="Personal Trainer"/>
                    </a>
                </div>
              );
        } else 
        if (props.visitouPaginaInfo === true) {    // Já visitou
            props.history.replace("/horarios/0");
            return null;
        }
    }  
}
    
export default PaginaInfo;
  