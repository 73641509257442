import httpServiceNewAccount from "./httpServiceNewAccount";

export function getEmailVerify(user) {
  const apiEndpoint =
    process.env["REACT_APP_CUSTOMER_" + user.customerAbreviatura] + "/peoples";

  //2020-06-11
  //return httpServiceNewAccount.get(apiEndpoint + "/" + user.email);
  return httpServiceNewAccount.get(
    //2023-09-10
    //apiEndpoint + "/" + user.codigo + "/" + user.telemovel
    apiEndpoint +
      "/" +
      user.customerAbreviatura.toUpperCase() +
      "/" +
      user.codigo +
      "/" +
      user.telemovel
  );
}
