import httpCustomer from "./httpServiceCustomer";
import auth from "./authService";

const apiEndpoint = "/horarios";
const user = auth.getCurrentUser();

export function getHorarios(p) {
  if (!user) return httpCustomer.get(apiEndpoint);
  else {
    let aux;

    if (typeof p === "undefined") {
      aux = window.location.pathname.substring(
        window.location.pathname.length - 1,
        window.location.pathname.length
      );
      if (aux !== "0" && aux !== "1") aux = "0";
    } else aux = p;

    if (
      user.customerAbreviatura === "CONTABO" ||
      user.customerAbreviatura === "LIBERTY" ||
      user.customerAbreviatura === "LIFECLUB" /* kika */ ||
      user.customerAbreviatura === "DEMOGYM" /* kika 2023-09-11*/
    ) {
      return httpCustomer.get(apiEndpoint + "/" + aux);
    } else {
      return httpCustomer.get(apiEndpoint + "/" + user.cod_atleta + "/" + aux);
    }
  }
}
