/* 
 2021-01-15
*/

import httpCustomer from "./httpServiceCustomer";

const apiEndpoint = "/videos";

export function getVideos() {
    return httpCustomer.get(apiEndpoint);
}

//2021-01-19
export function postConsumoVideos(cod_atleta, cod_actividade) {
    return httpCustomer.post(apiEndpoint + "/consumos", {cod_atleta,cod_actividade});
}
