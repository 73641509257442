import React from "react";
import Modal from "react-modal";

const DialogBox = ({
  isOpen,
  item,
  onRequestClose,
  onRequestContinuar,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "500px",
          maxWidth: "100%",
        },
      }}
    >
      {/* 2021-11-05 <p>{children}</p> */}
      <div>{children}</div>
      
      <div>
        <button
          className="btn btn-secondary"
          onClick={() => onRequestClose(item)}
        >
          Não
        </button>
        <button
          className="btn btn-primary m-4"
          onClick={() => onRequestContinuar(item)}
        >
          Sim
        </button>
      </div>
    </Modal>
  );
};

export default DialogBox;
