/* 
 2021-01-19
*/

import httpCustomer from "./httpServiceCustomer";

const apiEndpoint = "/info";

export function getExibePaginaInfo() {
    return httpCustomer.get(apiEndpoint);
}

export function getVisitouPaginaInfo(cod_atleta) {
    return httpCustomer.get(apiEndpoint + "/" + cod_atleta);
}

export function postVisitouPaginaInfo(cod_atleta) {
    return httpCustomer.post(apiEndpoint + "/" + cod_atleta);
}
